


















































import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Tile = {
  name: string;
  concierge?: {
    image?: {
      url: string;
    };
    concierge_info?: string;
  };
};

@Component
export default class ConciergeComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`Concierge: ${this.tile?.name}`);
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get conciergeImage() {
    return this.tile.concierge?.image?.url;
  }

  get conciergeInfo() {
    return this.tile.concierge?.concierge_info;
  }
}
